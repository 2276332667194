@font-face {
  font-family: "Philosopher";
  src: local("Philosopher"),
  url("/src/assets/Philosopher-Regular.ttf") format("truetype");

}

body, html {
  background-color: black;
  font-family: "Philosopher", "Yrsa", serif;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #AFAFB9;
  width: 100%;
  background-image: url("/src/assets/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#root {
  display: flex;
  width: 100%;
  flex-direction: column;
}

#app a {
  color: #AFAFB9;
}

#menu {
  text-align: left;
  position: fixed;
  display: flex;
  flex-direction: column;
}

#menu a {
  margin: 10px;
  color: #AFAFB9;
}

#logo {
  margin-bottom: 25px;
}

#cover p {
  font-size: 18px;
}

h1 {
  font-size: 42px;
}

h2 {
  margin-top: -25px;
  margin-bottom: 0;
}

h3 {
  font-size: 30px;
}

p {
  font-size: 20px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.block-container {
  padding: 20px;
}

#music {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#music iframe {
  padding: 25px;
}

#merch {
  background-color: black;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

#merch .merch-line {
  flex-direction: row;
  display: flex;
}

#merch .item {
  align-self: center;
}

#merch img {
  cursor: pointer;
}

#contact img {
  width: 50px;
  margin: 15px;
}

#credit-photo {
  font-size: 12px;
}
#credit-photo a {
  color: #AFAFB9;
}
#not-found a {
  color: #AFAFB9;
}

@media only screen and (max-width: 1280px) {
  #menu, #promote, #youtube-clip, #bandcamp {
    display: none;
  }

  #new-merch, #old-but-gold-merch {
    flex-direction: column;
  }

  #merch .merch-line {
    flex-direction: column;
    width: 100%;
  }

  #merch .item {
    width: 100%;
  }
}
